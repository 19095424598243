import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Member} from "../../interfaces/Member";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import Layout from "../../components/Navigation/layout";
import VendorAccountPage from "../../PageTypes/VendorAccountPage/VendorAccountPage";
import Loading from "../../components/Loading/Loading";
import {setMemberID} from '../../stores/member/memberSlice';
import {Container} from "react-bootstrap";
import { BrowserRouter as Router } from 'react-router-dom';

const mapState = ({member, auth}) => ({
    member,
    auth
})

const mapDispatch = {
    setMemberID
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>

const VendorPage: FC<PropsFromRedux & any> = ({auth, member, location}) => {
    const [user, setUser] = useState<Member>(null);
    // @ts-ignore
    const {state = {}} = location
    // @ts-ignore
    const {key} = state ?? {key: false};
    useEffect(() => {
        if (member && auth.Key) {
            Client.Member.current(auth.Key).then((res) => {
                setUser(res.data);
                setMemberID(res.data.ID);
            }).catch(handleApiError);
        }
    }, [])

    return (
        <Layout pageTitle="Mein Händlerprofil">
            {user ?
                <>
                    {user.VendorID ?
                        <Router>
                            <VendorAccountPage user={user} keyFromLocation={key}/>
                        </Router>
                        : <Loading type={"border"} message={"Daten werden geladen"}/>
                    }
                </> : <Loading message={"Daten werden geladen"} type={"border"}/>
            }

        </Layout>
    )
}

export default connector(VendorPage);
