import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import {Row} from "react-bootstrap";
import Loading from "../Loading/Loading";

const mapState = ({member, auth}) => ({
    member,
    auth
})

const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    fetchedData(state: boolean): void
}

const VendorStats: FC<PropsFromRedux & any> = ({member, auth, user}) => {
    const [stats, setStats] = useState();
    const [productData, setProductData] = useState({HTML: '', Script: ''});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Client.Auth.authGetWithAction(auth.Key, 'Vendor', user.VendorID, 'getVendorStats').then((res) => {
            setStats(res.data);
            setLoading(false);
        }).catch(handleApiError)

        const script = document.createElement('script');

        script.src = "https://cdn.jsdelivr.net/npm/chart.js@2.8.0";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [])

    useEffect(() => {
        // @ts-ignore
        if (stats && stats.ProductsSold) {
            // @ts-ignore
            let prodData = stats.ProductsSold;
            let extractscript = /<script\b[^>]*>([\s\S]*?)<\/script>/g.exec(prodData);
            if (extractscript && extractscript.length) {
                prodData = prodData.replace(extractscript[1], "");
                setProductData({
                    HTML: prodData ?? '',
                    Script: extractscript[1] ?? '',
                })
            }

        }

    }, [stats])

    useEffect(() => {
        console.log(productData?.Script)
       // window.eval(productData?.Script);
    }, [productData])

    return (
        <div className="py-4">
            {loading ?
                <Loading type={"border"} message={"Statistiken werden geladen..."}/> :
                <Row dangerouslySetInnerHTML={{__html: productData.HTML}}/>
            }
        </div>
    )
}

export default connector(VendorStats);
