// extracted by mini-css-extract-plugin
export var active = "EditVendorData-module--active--X56PV";
export var card = "EditVendorData-module--card--R49v8";
export var cardCollapse = "EditVendorData-module--cardCollapse--C58rL";
export var cardHeader = "EditVendorData-module--cardHeader--YgUpK";
export var dropDown = "EditVendorData-module--dropDown--LZN5D";
export var dropDownChevron = "EditVendorData-module--dropDownChevron--OEDY0";
export var form = "EditVendorData-module--form--Tvb1h";
export var icon = "EditVendorData-module--icon--GAVwa";
export var inputField = "EditVendorData-module--inputField--G2NHY";
export var inputLabel = "EditVendorData-module--inputLabel--MUr6R";
export var loading = "EditVendorData-module--loading--yaffq";
export var mainHeading = "EditVendorData-module--mainHeading--R0io2";
export var subHeading = "EditVendorData-module--subHeading--Btg8u";
export var submitButton = "EditVendorData-module--submitButton--AXbcT";
export var tab = "EditVendorData-module--tab--n5SRU";
export var tabIcon = "EditVendorData-module--tabIcon--hCB3v";
export var textArea = "EditVendorData-module--textArea---DY6I";