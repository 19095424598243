import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import AccountBanner from "../../components/AccountBanner/AccountBanner";
import {Dropdown, Row, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxFull, faChartArea, faExternalLink, faReceipt, faUserCircle} from "@fortawesome/pro-light-svg-icons";
import {Link} from "gatsby";


// @ts-ignore
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import * as style from './VendorAccountPage.module.scss';
import EditVendorData from "../../components/EditVendorData/EditVendorData";
import VendorProductOverview from "../../components/VendorProductOverview/VendorProductOverview";
import {Client, handleApiError} from "../../services/ApiService";
import {setBasketAmount, setBasketID} from "../../stores/basket/basketSlice";
import {connect, ConnectedProps} from "react-redux";
import Orders from "../../components/Orders/Orders";
import StripeErrorBanner from "../../components/StripeErrorBanner/StripeErrorBanner";
import VendorStats from "../../components/VendorStats/VendorStats";
import PackageProducts from "../../components/PackageProducts/PackageProducts";
import Address from "../../components/Address/Address";
import InvoiceOverview from "../../components/InvoiceOverview/InvoiceOverview";


const mapState = ({auth, member}) => ({
    auth,
    member
})

const mapDispatch = {
    setBasketID,
    setBasketAmount
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    user: any,
    keyFromLocation?: any,
    prevPath: any,
    props: any,
}

const VendorAccountPage: FC<Props> = ({keyFromLocation, user, auth, member}) => {
    const [key, setKey] = useState('orders');
    const [iframeLink, setIframeLink] = useState('');
    const [messages, setMessages] = useState();
    const [deadline, setDeadline] = useState('');
    const [selection, setSelection] = useState('Kundenbestellungen');


    useEffect(() => {
        if (typeof user.VendorID === 'number') {
            Client.Auth.authGetWithAction(auth.Key, 'Vendor', user.VendorID, 'checkStripeStatus').then((resCheck) => {
                setDeadline(resCheck.data.Deadline);
                setMessages(resCheck.data.Messages);
                Client.AuthVendor.authGet(auth.Key, 'LoginLink', user.VendorID).then((res) => {
                    setIframeLink(res.data.Link)
                }).catch(handleApiError)
            }).catch(handleApiError);

            if (keyFromLocation === true) {
                setKey('products');
            }
        }
    }, [user])

    useEffect(() => {
        if (key === "paymentData") {
            window.open(iframeLink, '_blank').focus();
            setKey("vendorData");
        }
    }, [key])

    useEffect(() => {
        if (window.location.toString().includes("tab=produkte")) {
            setKey('products');
        }
    }, [])

    function getCurrentDisplay() {
        switch (selection) {
            case 'Kundenbestellungen':
                return <Orders type={"vendor"}/>
            case 'Händlerdaten':
                return <EditVendorData user={user}/>
            case 'Zahlungsdaten':
                return <Address user={user}/>
            case 'Produkte':
                return <VendorProductOverview user={user}/>
            case 'Statistiken':
                return <VendorStats user={user}/>
            case 'Verpackungen':
                return <PackageProducts/>
            default:
                return <Orders type={"member"}/>
        }
    }

    return (
        <>
            {typeof messages !== 'undefined' && iframeLink ?
                <StripeErrorBanner errors={messages} deadline={deadline} stripeLink={iframeLink}/>
                : null
            }
            <AccountBanner user={user} isVendor={true}/>
            <div className={style.customContainer + ' ' + style.navTabs + ' d-none d-lg-block'}>
                <Tabs
                    id="vendorprofiletabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    defaultActiveKey="vendorData"
                >
                    {/*@ts-ignore*/}
                    <Tab eventKey="orders"
                         title={<><FontAwesomeIcon icon={faBoxFull} className={style.icon}/> Kundenbestellungen </>}>
                        <Row>
                            <h2 className={style.mainHeading}>Kundenbestellungen</h2>
                            <Orders type={"vendor"}/>
                        </Row>
                    </Tab>
                    {/*@ts-ignore*/}
                    <Tab eventKey="vendorData"
                         title={<><FontAwesomeIcon icon={faUserCircle} className={style.icon}/> Händlerdaten </>}>
                        <EditVendorData user={user}/>
                    </Tab>
                    {/*@ts-ignore*/}
                    {iframeLink &&
                    <Tab eventKey="paymentData"
                         title={<><FontAwesomeIcon icon={faExternalLink}
                                                   className={style.icon}/> Zahlungsdaten </>}>
                    </Tab>
                    }
                    {/*@ts-ignore*/}
                    <Tab eventKey="products"
                         title={<><FontAwesomeIcon icon={faBoxFull} className={style.icon}/> Produkte </>}>
                        <VendorProductOverview user={user}/>
                    </Tab>
                    {/*@ts-ignore*/}
                    <Tab eventKey="stats"
                         title={<><FontAwesomeIcon icon={faChartArea} className={style.icon}/> Statistiken </>}>
                        <VendorStats user={user}/>
                    </Tab>
                    {/*@ts-ignore*/}
                    <Tab eventKey={"invoices"}
                         title={<><FontAwesomeIcon icon={faReceipt} className={style.icon}/> Rechnungen </>}>
                        <InvoiceOverview vendorID={member.Member.VendorID} authKey={auth.Key}/>
                    </Tab>
                    {/*@ts-ignore*/}
                    <Tab eventKey="vendorOnlyProducts"
                         title={<><FontAwesomeIcon icon={faBoxFull} className={style.icon}/> Verpackungen </>}>
                        <PackageProducts/>
                    </Tab>
                </Tabs>
            </div>
            <div className={style.customContainer + ' ' + style.navTabs + ' d-block d-lg-none'}>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className={style.fakeTab}>
                        {selection}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={style.fakeHolder}>
                        <Dropdown.Item
                            onClick={() => setSelection('Kundenbestellungen')}
                        >
                            <FontAwesomeIcon icon={faBoxFull} className={style.icon + ' me-2'}/>
                            Kundenbestellungen
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => setSelection('Händlerdaten')}>
                            <FontAwesomeIcon icon={faUserCircle}
                                             className={style.icon + ' me-2'}/>
                            Händlerdaten
                        </Dropdown.Item>
                        {iframeLink ?
                            <Dropdown.Item
                                onClick={() => {
                                    setSelection('Zahlungsdaten')
                                    window.open(iframeLink)
                                }}>
                                <FontAwesomeIcon icon={faExternalLink}
                                                 className={style.icon + ' me-2'}/>
                                Zahlungsdaten
                            </Dropdown.Item> : null
                        }
                        <Dropdown.Item onClick={() => setSelection('Produkte')}>
                            <FontAwesomeIcon icon={faBoxFull}
                                             className={style.icon + ' me-2'}/>
                            Produkte
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelection('Statistiken')}>
                            <FontAwesomeIcon icon={faChartArea}
                                             className={style.icon + ' me-2'}/>
                            Statistiken
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelection('Verpackungen')}>
                            <FontAwesomeIcon icon={faBoxFull}
                                             className={style.icon + ' me-2'}/>
                            Verpackungen
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {getCurrentDisplay()}
            </div>
        </>

    )
}

export default connector(VendorAccountPage);
