import * as React from "react";
import {FC, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import OffersPreview from "../VendorPage/OffersPreview/OffersPreview";

const mapState = ({member, auth}) => ({
    member, auth
})

const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux;

const PackageProducts: ({member, auth}: { member: any; auth: any }) => JSX.Element = ({member, auth}) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        Client.Auth.authGetNoID(auth.Key, 'Category/40').then((res) => {
            setProducts(res.data.Products ?? []);
        }).catch(handleApiError)
    }, [])

    return (
        <div className={"pb-5"}>
            <OffersPreview showAnyway={true} products={products}/>
        </div>
    )
}

export default connector(PackageProducts);
