import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import classNames from "classnames/bind";
import VendorSearchBar from "../VendorSearchBar/VendorSearchBar";
// @ts-ignore
import * as style from './VendorProductFilters.module.scss';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListUl} from "@fortawesome/pro-light-svg-icons";
import {faTh} from "@fortawesome/pro-solid-svg-icons";

type PFProps = {
    filteringDone(products: Array<any>): void,
    toDisplayType(type: string): void,
    user: any,
    refreshProducts(type: boolean): void,
}

const VendorProductFilters: FC<PFProps> = ({filteringDone, toDisplayType, user, refreshProducts}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [productFilters, setProductFilters] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sorting, setSorting] = useState('');
    const [displayType, setDisplayType] = useState('detail');

    function handleSearch() {
        Client.Raw.get('Product/', {params: getSearchParams()}).then((res) => {
            setFilteredProducts(res.data);
            refreshProducts(false);
        }).catch(handleApiError)
    }

    useEffect(() => {
        handleSearch()
    }, [refreshProducts])

    function getSearchParams() {
        let params = {};

        params['filter[VendorID]'] = user.VendorID;

        if (searchTerm) {
            params['filter[Title:PartialMatch]'] = searchTerm;
        }
        if (productFilters.length) {
            productFilters.forEach((fil: any) => {
                params[`filter[${fil}]`] = 1
            })
        }
        if (sorting) {
            params['sort'] = sorting;
        }


        return params;
    }

    useEffect(() => {
        filteringDone(filteredProducts)
    }, [filteredProducts])


    useEffect(() => {
        handleSearch()
    }, [searchTerm, sorting, productFilters])

    useEffect(() => {
        toDisplayType(displayType)
    }, [displayType])

    function handleProdFilter(event) {
        if (event.target.checked) {
            setProductFilters([...productFilters, event.target.value])
        } else {
            setProductFilters(productFilters.filter((cat, index) => {
                return cat !== event.target.value
            }));
        }
    }

    function handleSortFilter(event) {
        setSorting(event.target.value)
    }

    let cl = classNames.bind(style);
    return (
        <Row className="justify-content-center align-items-center">
            <h2 className={style.heading}>Produkte verwalten</h2>
            <Col lg={3}>
                <VendorSearchBar handleVendorSearch={(term) => {
                    if (term !== searchTerm) {
                        setSearchTerm(term);
                    }
                }}/>
            </Col>
            <Col lg={4}>
                {/*<Form.Group controlId={"filter"} className="d-flex flex-row justify-content-evenly w-100">*/}
                {/*    <Form.Check type="checkbox" label={"Top-Produkte"} className={style.checkbox} value={"Promoted"}*/}
                {/*                onChange={(event => handleProdFilter(event))}/>*/}
                {/*    <Form.Check type="checkbox" label={"SALE-Produkte"} className={style.checkbox} value={"Sale"}*/}
                {/*                onChange={(event => handleProdFilter(event))}/>*/}
                {/*</Form.Group>*/}
            </Col>
            <Col lg={{span: 4, offset: 1}}>
                <Row>
                    <Col lg={8}>
                        <Form.Group controlId={"sort"} className={style.dropdownWrapper + " py-4"} >
                            <Form.Control as="select" onChange={(event) => handleSortFilter(event)}
                                          className={style.vendorDropdown}>
                                <option disabled={true}>Sortieren nach</option>
                                <option value={"Title ASC"}>Produktname aufsteigend</option>
                                <option value={"Title DESC"}>Produktname absteigend</option>
                                <option value={"CategoryID DESC"}>Kategorie</option>
                                <option value={"Sale DESC"}>Sale</option>
                                <option value={"Promoted DESC"}>Top-Produkte</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="d-flex justify-content-evenly py-4">
                        <div className={displayType === 'detail' ? style.displayFilterActive : style.displayFilter}
                             onClick={() => setDisplayType('detail')}>
                            <FontAwesomeIcon icon={faListUl}/>
                        </div>
                        <div className={displayType === 'non-detail' ? style.displayFilterActive : style.displayFilter}
                             onClick={() => {
                                 setDisplayType('non-detail')
                             }}>
                            <FontAwesomeIcon icon={faTh}/>
                        </div>
                    </Col>
                </Row>
            </Col>
            <hr/>
        </Row>
    )
}

export default VendorProductFilters;
