import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import VendorProductFilters from "../VendorProductFilters/VendorProductFilters";
import {Link, navigate} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPause, faPenAlt, faPlus, faTimes, faTrashAlt} from "@fortawesome/pro-light-svg-icons";
// @ts-ignore
import * as style from './VendorProductOverview.module.scss';
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";
import {connect, ConnectedProps} from "react-redux";
import Loading from "../Loading/Loading";
import "../../theme/main.module.scss";
import {setLastEditedProductID} from '../../stores/member/memberSlice';
import {StaticImage} from "gatsby-plugin-image";

const mapState = ({auth}) => ({
    auth
})

const mapDispatch = {
    setLastEditedProductID
}


const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    user: any,
}

const VendorProductOverview: FC<Props> = ({user, auth, setLastEditedProductID}) => {
    const [products, setProducts] = useState([])
    const [displayType, setDisplayType] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [productToDelete, setProductToDelete] = useState({});
    const [refresh, setRefresh] = useState(true);

    function deleteFunction(prod) {
        setShowDeleteModal(true);
        setProductToDelete(prod)
    }

    function freezeProduct(id) {
        setRefresh(true);
        Client.Auth.authPut(auth.Key, 'Product', id, {Hidden: true}).then((res) => {
            {/*// @ts-ignore*/
            }
            toast.success('Das Produkt ' + productToDelete.Title + ' wurde erfolgreich pausiert!');
            setShowDeleteModal(false);
            setRefresh(false);
        }).catch(handleApiError);
    }

    function unfreezeProduct(id) {
        setRefresh(true);
        Client.Auth.authPut(auth.Key, 'Product', id, {Hidden: false}).then((res) => {
            {/*// @ts-ignore*/
            }
            toast.success('Das Produkt ' + productToDelete.Title + ' wurde erfolgreich unpausiert!');
            setShowDeleteModal(false);
            setRefresh(false);
        }).catch(handleApiError);
    }

    function confirmDelete(id) {
        setRefresh(true);
        Client.Auth.authDelete(auth.Key, 'Product', id).then((res) => {
            {/*// @ts-ignore*/
            }
            toast.success('Das Produkt ' + productToDelete.Title + ' wurde erfolgreich gelöscht!');
            setShowDeleteModal(false);
            setRefresh(false);
        }).catch(handleApiError);
    }

    return (
        <>
            <Row className={"pt-2 pb-5"}>
                <VendorProductFilters user={user} refreshProducts={setRefresh} toDisplayType={setDisplayType}
                                      filteringDone={setProducts}/>
                {refresh ?
                    <Loading type={"border"} message={"Produkte werden geladen."}/> :
                    <>
                        <Col lg={{span: 6, offset: 3}} className={"d-flex justify-content-center"}>
                            <Button className={style.addProduct} as={Link} to={'/modifyProduct'}
                                    onClick={() => setLastEditedProductID('new')}>
                                <FontAwesomeIcon icon={faPlus} className={style.icon}/>
                                Neues Produkt hinzufügen
                            </Button>
                        </Col>
                        {displayType === 'detail' ?
                            <>
                                {products.map((prod, index) => {
                                    return (
                                        <Col lg={4} xs={6} className={"d-flex"} key={index}>
                                            <div className={style.prodCard}>
                                                <Link to={'/modifyProduct'}
                                                      className={style.link}
                                                      onClick={() => setLastEditedProductID(prod.ID)}
                                                >
                                                    <Row
                                                        className="d-flex justify-content-center align-items-center position-relative">
                                                        <Col lg={3} className="pb-4">
                                                            {prod.PreviewImage ?
                                                                <img src={prod.PreviewImage}
                                                                     className={style.detailImage}/>
                                                                : <StaticImage
                                                                    src={"../../images/fairstaerkt_emblem_color.png"}
                                                                    className={style.detailImage}
                                                                    placeholder={"traceSVG"} alt={"background"}/>
                                                            }
                                                        </Col>
                                                        <Col lg={{span: 7, offset: 2}}
                                                             className="d-flex flex-column pb-4">
                                                            <span className={style.category}>{prod.CategoryTitle}</span>
                                                            <span className={style.name}>{prod.Title}</span>
                                                            <span className={style.price}>{prod.NicePrice}</span>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                                <Row
                                                    className="d-flex justify-content-center align-items-center position-relative">
                                                    <hr/>
                                                    <div
                                                         className="d-flex justify-content-evenly align-items-center">
                                                        <Row style={{width: '100%'}}>
                                                            <Col lg={4} className={style.vendorCol}>
                                                        <span className={style.detailIconHolder}>
                                                            <Link to={'/modifyProduct'} state={{id: prod.ID}}
                                                                  onClick={() => setLastEditedProductID(prod.ID)}>
                                                                <FontAwesomeIcon icon={faPenAlt}/> Bearbeiten
                                                            </Link>
                                                        </span>
                                                            </Col>
                                                            <Col lg={4} className={style.vendorCol}>
                                                            <span className={style.detailIconHolder}
                                                                  onClick={() => deleteFunction(prod)}>
                                                        <FontAwesomeIcon icon={faTrashAlt}/> Löschen
                                                        </span>
                                                            </Col>
                                                            <Col lg={4} className={style.vendorCol}>
                                                            <span className={style.detailIconHolder}
                                                                  onClick={() => navigate(`/${prod.CategoryURLTitle}/${prod.URLTitle}`)}>
                                                            <FontAwesomeIcon icon={faEye}/> Anzeigen
                                                        </span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Row>
                                                {prod.Hidden ?
                                                    <OverlayTrigger
                                                        key={"top-vendor"}
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top-vendor`}>
                                                                Tooltip on <strong>top</strong>.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <>
                                                                    <span className={style.freezeHolder}
                                                                          onClick={() => unfreezeProduct(prod.ID)}>
                                                                        <FontAwesomeIcon icon={faPause}/>
                                                                    </span>
                                                        </>
                                                    </OverlayTrigger> : null
                                                }
                                            </div>
                                        </Col>
                                    )
                                })}
                            </>
                            :
                            <>
                                {products.map((item, index) => {
                                    return (
                                        <Col className={style.holder} lg={4} key={index}>
                                            <Link to={'/modifyProduct'}
                                                  className={style.link}
                                                  onClick={() => setLastEditedProductID(item.ID)}
                                            >
                                                <div className={style.iconHolder}>
                                                    {item.PreviewImage ?
                                                        <img src={item.PreviewImage}
                                                             className={style.icon}/>
                                                        : <StaticImage
                                                            src={"../../images/fairstaerkt_emblem_color.png"}
                                                            placeholder={"traceSVG"} alt={"background"}/>
                                                    }
                                                </div>
                                            </Link>
                                            <span className={style.editHolder}>
                                                    <Link to={`/${item.CategoryURLTitle}/${item.URLTitle}`}
                                                          state={{id: item.ID}}
                                                          onClick={() => setLastEditedProductID(item.ID)}>
                                                        <FontAwesomeIcon icon={faEye} className={style.edit}/>
                                                    </Link>
                                                </span>
                                            <span className={style.deleteHolder}
                                                  onClick={() => deleteFunction(item)}
                                            >
                                                    <FontAwesomeIcon icon={faTrashAlt} className={style.trash}/>
                                                </span>
                                            {item.Hidden ?
                                                <OverlayTrigger
                                                    key={"top-vendor"}
                                                    placement={"top"}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top-vendor`}>
                                                            Tooltip on <strong>top</strong>.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <>
                                                                    <span className={style.freezeHolderDetail}
                                                                          onClick={() => unfreezeProduct(item.ID)}>
                                                                        <FontAwesomeIcon icon={faPause}
                                                                                         className={style.pause}/>
                                                                    </span>
                                                    </>
                                                </OverlayTrigger> : null
                                            }
                                            <h5 className={style.category}>
                                                {item.CategoryTitle}
                                            </h5>
                                            <h3 className={style.name}>
                                                {item.Title}
                                            </h3>
                                            <h3 className={style.price}>
                                                {item.NicePrice}
                                            </h3>
                                            <p className={style.mwst}>
                                                inkl. MwSt.
                                            </p>

                                        </Col>
                                    )
                                })}
                            </>
                        }
                    </>
                }
            </Row>
            {showDeleteModal ?
                <Modal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName={style.modal}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter" className={style.modalTitle}>
                            <Button onClick={() => setShowDeleteModal(false)} style={{float: "right"}}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </Button>
                            {/*// @ts-ignore*/}
                            {productToDelete.Title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"text-center"}>
                        <div className={"text-center"}>
                            <h4>Möchtest du das Produkt wirklich entgültig löschen?</h4>
                            <p>Es besteht auch die Möglichkeit, dass Produkt auf unbestimmte Zeit zu pausieren!</p>
                        </div>
                        <Row>
                            <Col lg={{span: 4, offset: 2}} className={"d-flex justify-content-center"}>
                                {/*// @ts-ignore*/}
                                <Button onClick={() => confirmDelete(productToDelete.ID)}
                                        className={style.deleteButton}>
                                    <FontAwesomeIcon icon={faTrashAlt} className={"me-2"}/>
                                    <span> Produkt löschen </span>
                                </Button>
                            </Col>
                            <Col lg={4} className={"d-flex justify-content-center"}>
                                {/*// @ts-ignore*/}
                                <Button onClick={() => freezeProduct(productToDelete.ID)} className={style.pauseButton}>
                                    <FontAwesomeIcon icon={faPause} className={"me-2"}/>
                                    <span> Produkt pausieren </span>
                                </Button>
                            </Col>
                            <Col lg={2}>

                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal> : null
            }
        </>
    )
}

export default connector(VendorProductOverview);
