import * as React from "react";
import {FC} from "react";
// @ts-ignore
import * as style from './StripeErrorBanner.module.scss';
import {Alert} from "react-bootstrap";
import {Link} from "gatsby";
import {connect, ConnectedProps} from "react-redux";


const mapState = ({member}) => ({
    member
})
const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    errors: any,
    deadline: string,
    stripeLink: string,
}

const StripeErrorBanner: FC<Props> = ({deadline, errors, stripeLink, member}) => {

    return (
        <>
            {errors ?
                <Alert variant={"danger"}
                       className={style.errorAlert}>
                    <span
                        className={style.header + " text-center"}>Mit deinen Zahlungsinformationen stimmt etwas nicht!
                     </span>
                    {deadline ?
                        <small>Änderungsfrist: {deadline}</small>
                        : null
                    }
                    {errors ?
                        <>
                            <ul className={"list-unstyled container d-flex justify-content-center flex-column align-items-center " + style.errorList}>
                                {
                                    errors.map((error, index) => {
                                        return (
                                            <li key={index} className={"py-1"}>
                                                {error}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            {stripeLink ?
                                <Link to={stripeLink} target={"_blank"} className={style.editLink}>
                                    Jetzt bearbeiten
                                </Link>
                                : null
                            }
                        </> : null
                    }
                </Alert>
                : null}
        </>
    )
}

export default connector(StripeErrorBanner);
