import * as React from "react";
import {FC, useEffect, useState} from "react";
// @ts-ignore
import * as style from './VendorSearchBar.module.scss';

type VSBProps = {
    handleVendorSearch(searchTerm: string): void,
    vendorSearchBar?: boolean
}

const VendorSearchBar: FC<VSBProps> = ({handleVendorSearch, vendorSearchBar}) => {
    const [searchTerm, setSearchTerm] = useState(new URLSearchParams(location.search).get('search') ?? '');



    return (
        <input
            className={vendorSearchBar ? style.vendorBar : style.searchBar}
            key="searchbar"
            value={searchTerm}
            placeholder={"Suche"}
            onChange={(e) => {
                setSearchTerm(e.target.value);
            }}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    handleVendorSearch(searchTerm)
                }
            }}
        />
    )
}

export default VendorSearchBar;
