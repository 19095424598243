// @ts-ignore
import * as React from 'react';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Client, handleApiError} from "../../services/ApiService";
import {Accordion, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './InvoiceOverview.module.scss';
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";

type props = {
    vendorID: string
    authKey: string
}

function InvoiceOverview(props) {
    const [fees, setFees] = useState([]);
    const [comissions, setComissions] = useState([]);

    useEffect(() => {
        Client.Auth.authGetNoID(props.authKey, 'Fee?sort=Created DESC&filter[VendorID:ExactMatch]=' + props.vendorID).then((res) => {
            setFees(res.data);
        });
        Client.Auth.authGetNoID(props.authKey, 'Invoice?sort=Created DESC&filter[Type:ExactMatch]=Commission&filter[VendorID]=' + props.vendorID).then((res) => {
            setComissions(res.data);
        });
    }, []);

    return (
        <>
            {fees.length ?
                <>
                    {fees.map((fee, index) => {
                        return (
                            <Row className={style.invoiceRow + ' mt-5'} key={index}>
                                <Col lg={3} md={2} className={style.invoicePrice}>
                                    {fee.NiceBrutto}
                                </Col>
                                <Col lg={3} md={2} className={style.invoiceTitle}>
                                    {fee.Title}
                                </Col>
                                <Col lg={3} md={2} className={style.invoiceDate}>
                                    {fee.NiceDate}
                                </Col>
                                <Col lg={2} md={2} className={style.invoiceDate}>
                                    {fee.InvoiceNumber}
                                </Col>
                                <Col lg={1} md={1} className={style.invoiceLink}>
                                    <a href={fee.InvoiceURL} target={'_blank'}>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    </a>
                                </Col>
                            </Row>
                        )
                    })}
                </>
                : <div className={'pb-3 pt-5 text-center'}><h4>Keine Rechnungen gefunden.</h4></div>}
            {comissions.length ?
                <>
                    {comissions.map((com, index) => {
                        return (
                            <Row className={style.invoiceRow + ' mt-5'} key={index}>
                                <Col lg={3} md={2} className={style.invoicePrice}>
                                   {com.NiceCommissionValue}
                                </Col>
                                <Col lg={3} md={2} className={style.invoiceTitle}>
                                    Kommissionsrechnung
                                </Col>
                                <Col lg={3} md={2} className={style.invoiceDate}>
                                    {com.StartDate}
                                </Col>
                                <Col lg={3} md={2} className={style.invoiceDate}>
                                    {com.Number}
                                </Col>
                                <Col lg={1} md={1} className={style.invoiceLink}>
                                    <a href={com.DocumentURL} target={'_blank'}>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    </a>
                                </Col>
                            </Row>
                        )
                    })}
                </> : <div className={'pb-5 pt-5 text-center'}><h4>Keine Kommissionsrechnungen verfügbar.</h4></div>
            }
        </>
    );
}

export default InvoiceOverview;
